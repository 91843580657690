import React from 'react'
import Logo from '../assets/LogoB.png'

const Empresa = () => {
    return (
        <div className='w-full  py-16 text-grey px-4'>
          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
          <img className='w-[500px] mx-auto m-4 p-8' src={Logo} alt='/' />
            <div className='flex flex-col justify-center'>
              <p className='font-bold '>A NOSSA HISTÓRIA</p>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>A CRIAR LIMPEZA DESDE 2023</h1>
              <p>
              Na AFCCLEAN, não nos limitamos a limpar estofos; estamos a alterar o panorama dos serviços de limpeza. O nosso principal objetivo é garantir a satisfação dos nossos clientes, proporcionando espaços agradáveis e confortáveis.
              Desafiamos os padrões convencionais, elevando a limpeza a uma arte refinada, com técnicas inovadoras e uma paixão pela excelência. Queremos que cada cliente experiencie o poder transformador de um estofo verdadeiramente limpo e revitalizado.
              Junta-te a nós nesta jornada para mudar o mundo, um estofo de cada vez. O futuro da limpeza começa agora na AFCCLEAN.
              </p>
            </div>
          </div>
        </div>
    );
}

export default Empresa