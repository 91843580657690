import React from "react";
import NavBar from "./components/NavBar";
import First from "./components/First";
import Empresa from "./components/Empresa";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Videos from "./components/Videos";

function App() {
  return (
    <div className=" bg-beige">
      <NavBar/>
      <First/>
      <Services/>
      <Empresa/>
      <Videos/>
      <Footer/>
    </div>
  );
}

export default App;
