import React from 'react'
import ReactPlayer from 'react-player'
import videoURL from '../assets/Video.webm'

const Videos = () => {
    return (
      <div className='w-full bg-grey py-16 text-brownwood px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'> 
          <div className='flex flex-col justify-center'>
            <p className='font-bold '>OS NOSSOS VIDEOS</p>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>A CRIAR LIMPEZA DESDE 2023</h1>
            <p>
            Descubra a arte da limpeza em ação! 
            Este vídeo da AFCCLEAN mostra como transformamos estofos sujos em verdadeiras obras-primas de frescura e beleza. 
            Veja como cada mancha é removida com precisão, deixando para trás apenas o brilho impecável. 
            Prepare-se para ficar impressionado com os resultados incríveis da nossa abordagem à limpeza. 
            Assista agora e deixe-se inspirar pela magia da renovação de estofos!
            </p>
          </div>

          <div className="m-auto pt-11">
          <ReactPlayer
              url={videoURL}
              width="320px"
              height="240px"
              controls
            />
          </div> 
        </div>
      </div>
    )
}

export default Videos