import Logo from '../assets/LogoB.png'
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaTiktok,
} from 'react-icons/fa';

const Navbar = () => {
  return (
    <div className='flex justify-between items-center max-w-[1240px] md:flex-row flex-col max-h-[640px] mx-auto px-4 text-black'>
  <img src={Logo} alt="Logo" className='w-64' />
  <div className='hidden md:flex justify-between px-0 my-6'>
    <a className='p-4' href='https://m.facebook.com/people/AFCClean/61550977680276/'>
      <FaFacebook size={30} />
    </a>
    <a className='p-4' href='https://www.instagram.com/afcclean/'>
      <FaInstagram size={30} />
    </a>
    <a className='p-4' href='https://wa.me/351926249441'>
      <FaWhatsapp size={30} />
    </a>
    <a className='p-4' href='https://www.youtube.com/@AFCClean'>
      <FaYoutube size={30} />
    </a>
    <a className='p-4' href='https://www.tiktok.com/@afcclean'>
      <FaTiktok size={30} />
    </a>
  </div>
  <div className='flex justify-between px-0 my-6 md:hidden'>
    <a className='p-4' href='https://m.facebook.com/people/AFCClean/61550977680276/'>
      <FaFacebook size={30} />
    </a>
    <a className='p-4' href='https://www.instagram.com/afcclean/'>
      <FaInstagram size={30} />
    </a>
    <a className='p-4' href='https://wa.me/351926249441'>
      <FaWhatsapp size={30} />
    </a>
    <a className='p-4' href='https://www.youtube.com/@AFCClean'>
      <FaYoutube size={30} />
    </a>
    <a className='p-4' href='https://www.tiktok.com/@afcclean'>
      <FaTiktok size={30} />
    </a>
  </div>
</div>
  );
};

export default Navbar;