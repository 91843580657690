import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaTiktok,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-full mx-auto py-16 px-4 flex gap-8 text-center justify-center items-center text-grey '>
      <div className='max-w-[800px]'>
        <h1 className='w-full text-3xl font-bold text-grey'>AFCCLEAN</h1>
        <p className='py-4'>Caso queiram acompanhar as nossas novidades, sigam-nos nas nossas redes sociais, ou contactem-nos diretamente pelo +351 926 249 441. Estamos sempre disponíveis para responder às vossas questões e ajudar-vos a manter os vossos estofos limpos e frescos.
        </p>
        <div className='flex justify-between px-12 my-6'>
          <a href='https://m.facebook.com/people/AFCClean/61550977680276/'>
            <FaFacebook size={30} />
          </a>
          <a href='https://www.instagram.com/afcclean/'>
            <FaInstagram size={30} />
          </a>
          <a href='https://wa.me/351926249441'>
            <FaWhatsapp size={30} />
          </a>
          <a href='https://www.youtube.com/@AFCClean'>
            <FaYoutube size={30} />
          </a>
          <a href='https://www.tiktok.com/@afcclean'>
            <FaTiktok size={30} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;