import React from 'react'
import Carousel from './Carousel'
import img1 from '../assets/1.png'
import img2 from '../assets/2.png'
import img3 from '../assets/3.png'
import img4 from '../assets/4.png'
import img5 from '../assets/5.png'
import img6 from '../assets/6.png'
import img7 from '../assets/7.png'
import img8 from '../assets/8.png'
import img9 from '../assets/9.png'
import img10 from '../assets/10.png'
import img11 from '../assets/11.png'
import img12 from '../assets/12.png'
import img13 from '../assets/13.png'
import img14 from '../assets/14.png'

const slides = [
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14]


const Services = () => {
    return (
        <div className='w-full py-16 text-beige bg-grey px-4'>
          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>  
            <div className='flex flex-col justify-center'>
              <p className='font-bold '>OS NOSSOS SERVIÇOS</p>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>PREZAMOS PELO BEM-ESTAR DOS NOSSOS CLIENTES</h1>
              <p>
              A nossa empresa é especialista na limpeza e higienização profissional de Sofás, Colchões, Tapetes, Interiores de Automóveis e Impermeabilização de Tecidos.
              Removemos toda a sujidade, nódoas, ácaros, maus odores e bactérias, transformando o ambiente do seu automóvel, da sua casa ou empresa num contexto mais agradável, saudável e confortável.
              Realizamos serviços ao domicilio, fazendo com que o cliente não tenha de se preocupar com nada. Apenas tem de nos contactar e nós tratamos do restante processo.
              </p>
            </div>

            <div className="w-[60%] m-auto pt-11">
              <Carousel slides={slides} />
            </div>
          </div>
        </div>
      );
}

export default Services