import React from 'react'
import { ReactTyped } from 'react-typed'
import Both from '../assets/Both.png'


const First = () => {
    return (
        <div className='text-black'>
          <div className='max-h-[1240px] md:mt-[-50px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
            <p className='text-grey font-bold p-2'>
              LADO A LADO COM O SEU BEM-ESTAR
            </p>
            <ReactTyped
                strings={['AFCCLEAN']}
                typeSpeed={40}
                backSpeed={50}
                loop
                className='md:text-5xl text-4xl font-bold md:pl-4 pl-2 p-4 text-grey'
                />
            <div className='flex justify-center items-center'>
              <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
                Limpeza, Higienização e Impermiabilização
              </p>
            </div>
            <p className='md:text-2xl text-xl font-bold text-gray-500'>Mantenha os seus estofos limpos e longe de infeções.</p>
            <a href='https://wa.me/351926249441' className='bg-brownwood w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Pedir Orçamento</a>
            <p className='md:text-2xl text-xl text-gray-500'>+351 926 249 441</p>
            <div className='p-10 flex flex-row'>
              <img src={Both} alt='sofa' className='h-[200px] md:h-[300px] mx-auto' />
            </div>
          </div>
        </div>
    );
}

export default First;